import * as React from "react";

function SvgFacebook(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.286 3.429H7.714A4.286 4.286 0 003.43 7.714v8.572a4.286 4.286 0 004.285 4.285h8.572a4.286 4.286 0 004.285-4.285V7.714a4.286 4.286 0 00-4.285-4.285z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.429 11.46a3.43 3.43 0 11-6.785 1.006 3.43 3.43 0 016.785-1.006z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <mask id="instagram_svg__a" fill="#fff">
                <circle cx={17.143} cy={6.857} r={0.857}/>
            </mask>
            <path
                d="M17 6.857c0-.079.064-.143.143-.143v2A1.857 1.857 0 0019 6.857h-2zm.143-.143c.079 0 .143.064.143.143h-2c0 1.026.831 1.857 1.857 1.857v-2zm.143.143a.143.143 0 01-.143.143V5a1.857 1.857 0 00-1.857 1.857h2zM17.143 7A.143.143 0 0117 6.857h2A1.857 1.857 0 0017.143 5v2z"
                fill="currentColor"
                mask="url(#instagram_svg__a)"
            />
        </svg>
    );
}

export default SvgFacebook;
