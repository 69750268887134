import * as React from "react";

function SvgStarFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 2.571l1.986 4.025 4.443.649-3.215 3.13.759 4.424L9 12.709 5.027 14.8l.759-4.423L2.57 7.245l4.443-.65L9 2.572z"
        stroke="currentColor"
        fill='currentColor'
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgStarFilled;
