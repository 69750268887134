import React, {Component} from "react";
import {motion} from "framer-motion";

class Reviews extends Component {
    state = {
        tab: 'parents'
    }

    render() {
        if (this.props.mobile) {
            return (
                <section className='col-ac-jc text-center pv-60'>
                    <p className={"splash-head-mobile"}>
                        The Reviews are In!
                    </p>
                    <div className='row-ac-jc'>
                        <div className={this.state.tab === 'parents' ? 'toggle-button' : 'toggle-button-inactive'}
                             onClick={() => this.setState({tab: 'parents'})}>Parents
                        </div>
                        <div className={this.state.tab === 'kids' ? 'toggle-button' : 'toggle-button-inactive'}
                             onClick={() => this.setState({tab: 'kids'})}>Kids
                        </div>
                    </div>
                    <div className='w-80p' style={{minHeight: 200}}>
                        {this.state.tab === 'kids' &&
                        <motion.div animate={{opacity: this.state.tab === 'kids' ? 1 : 0}} initial={{opacity: 0}}
                                    className={"col-ac-jc"}>
                            <p className={"small-headline-mobile"}>
                                My parents bought this for me and its dope. I brought this into show’n’tell and
                                got
                                to hit it with my teacher. I’m king of the playground again.
                            </p>
                            <p className={"feature-text-mobile mt-0 mb-32"}>
                                Chad (5yo)
                            </p>
                        </motion.div>
                        }
                        {this.state.tab === 'parents' &&
                        <motion.div className={"col-ac-jc"} animate={{opacity: this.state.tab === 'parents' ? 1 : 0}}
                                    initial={{opacity: 0}}>
                            <p className={"small-headline-mobile"}>
                                Bought this for my kids and they haven’t stopped hitting it the entire week! I
                                finally get a break from parenting. I feel like I got my life back!
                            </p>
                            <p className={"feature-text-mobile mt-0 mb-32"}>
                                Karen Smith
                            </p>
                        </motion.div>
                        }
                    </div>
                </section>
            )
        }
        return (
            <section className='"col-ac-jc p-100 text-center'>
                <div className={"col-ac-jc text-center"}>
                    <p className={"splash-head"}>
                        The Reviews are In!
                    </p>
                    <div className='row-ac'>
                        <div className={this.state.tab === 'parents' ? 'toggle-button' : 'toggle-button-inactive'}
                             onClick={() => this.setState({tab: 'parents'})}>Parents
                        </div>
                        <div className={this.state.tab === 'kids' ? 'toggle-button' : 'toggle-button-inactive'}
                             onClick={() => this.setState({tab: 'kids'})}>Kids
                        </div>
                    </div>
                    <div className='w-50p' style={{maxWidth: 400, minHeight: 200}}>
                        {this.state.tab === 'kids' &&
                        <motion.div animate={{opacity: this.state.tab === 'kids' ? 1 : 0}} initial={{opacity: 0}}
                                    className={"col-ac-jc"}>
                            <p className={"small-headline"}>
                                My parents bought this for me and its dope. I brought this into show’n’tell and
                                got
                                to hit it with my teacher. I’m king of the playground again.
                            </p>
                            <p className={"feature-text mt-0 mb-32"}>
                                Chad (5yo)
                            </p>
                        </motion.div>
                        }
                        {this.state.tab === 'parents' &&
                        <motion.div className={"col-ac-jc"} animate={{opacity: this.state.tab === 'parents' ? 1 : 0}}
                                    initial={{opacity: 0}}>
                            <p className={"small-headline"}>
                                Bought this for my kids and they haven’t stopped hitting it the entire week! I
                                finally get a break from parenting. I feel like I got my life back!
                            </p>
                            <p className={"feature-text mt-0 mb-32"}>
                                Karen Smith
                            </p>
                        </motion.div>
                        }
                    </div>
                </div>
                <button className='mr-16 red-button' onClick={() => this.props.history.push("/buy")}>
                    Buy Now
                </button>
            </section>
        )
    }
}

export default Reviews
