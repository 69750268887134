import { CONTRACT_ADDRESS, MINT_PRICE_WEI, MINT_FUNCTION_SELECTOR, MAINNET_CHAIN_ID } from "../consts/consts"

export const loadEthereum = async (callback) => {
    if (typeof window.ethereum !== 'undefined') {
        const chainId = await window.ethereum.request({
            method: 'eth_chainId'
        });
        console.log("LINE 8", chainId, chainId !== MAINNET_CHAIN_ID)
        if (chainId !== MAINNET_CHAIN_ID) {
            alert("You are not connected to the Ethereum Network, please change Metamask to an ETH endpoint")
        }
        const txhash = await requestAccount(window.ethereum, callback)
        console.log("LINE 13", txhash)
        return txhash;
    } else {
        alert(
            "Not able to locate an Ethereum connection, please install a Metamask wallet"
        )
    }
}

const requestAccount = async (ethereum, callback) => {
    ethereum
        .request({
            method: 'eth_requestAccounts'
        })
        .then(async (resp) => {
            console.log("LINE 27", resp[0])
            const txhash = await payNow(ethereum, resp[0], callback)
            return txhash
        })
        .catch((err) => {
            console.log("Line 33", err)
        })
}

const payNow = async (ethereum, from, callback) => {
    ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [{
                from: from,
                to: CONTRACT_ADDRESS,
                value: '0x' + ((MINT_PRICE_WEI).toString(16)),
                data: MINT_FUNCTION_SELECTOR,
            },],
        })
        .then((txHash) => {
            if (txHash) {
                console.log("LINE 48", txHash)
                callback(txHash)
            } else {
                console.log("Something went wrong. Please try again")
            }
            return txHash
        })
        .catch((error) => {
            console.log("Line 57", error)
        })
}