import React, {Component} from "react";
import exclamation from "../../images/exclamations.png";
import hookahs from "../../images/hookahs1.png";
import SvgStarFilled from "../../icons/StarFilled";
import gif from "../../images/hookah.gif";
import fda from "../../images/fdastamp.png";

class ProductSection extends Component {
    render() {
        if (this.props.mobile) {
            return (
                <>
                    <section className='section-padding-mobile pb-0 black-text' style={{backgroundColor: 'white'}}>
                        <p className={"splash-head-mobile mb-0"} style={{color: '#000000'}}>
                            All for one, and fun<br/>for all!
                        </p>
                        <p className={"headline-mobile"} style={{color: '#000000'}}>
                            Get the kids buzzing in a fun and safe way!
                        </p>
                        <ul className='pb-32'>
                            <li className='row-ac feature-text-mobile mb-8'>
                                <SvgStarFilled color={'#3078F6'} width={24} height={24}/>
                                <span className='black ml-12'><strong>15</strong> unique and adorable faces</span>
                            </li>
                            <li className='row-ac feature-text-mobile mb-8'>
                                <SvgStarFilled color={'#3078F6'} width={24} height={24}/>
                                <span className='black ml-12'>Easy access refill canister for the long sesh</span>
                            </li>
                            <li className='row-ac feature-text-mobile mb-8'>
                                <SvgStarFilled color={'#3078F6'} width={24} height={24}/>
                                <span className='black ml-12'>Soft silicon tube for enhanced grip</span>
                            </li>
                            <li className='row-ac feature-text-mobile mb-8'>
                                <SvgStarFilled color={'#3078F6'} width={24} height={24}/>
                                <span className='black ml-12'><span className='red'
                                                                    style={{fontWeight: 600}}>NEW&nbsp;</span> <strong>50</strong> disposable COVID safe tube tips included</span>
                            </li>
                        </ul>
                    </section>
                    <section style={{backgroundColor: 'white'}}>
                        <div className={"col-ac-jc black-text p-100 pt-0 headline"}>
                            <img src={fda} style={{width: 70}}/>
                            <div className='body1-mobile' style={{textAlign: "center"}}>
                                My First Hookah is recommended by the Family Drugs Association as a fun and addicting
                                way to
                                get the kids started early. Just don't think too much about it.
                            </div>
                        </div>
                    </section>
                </>
            )
        }
        return (
            <>
                <section className='section-padding pb-0' style={{backgroundColor: 'white'}}>
                    <div className={"row-ac-jb text-center"} style={{height: '80vh'}}>
                        <div className={"col-ac-jc w-50p"}>
                            <p className={"splash-head mb-0"} style={{color: '#000000'}}>
                                All for one, and fun<br/>for all!
                            </p>
                            <p className={"headline"} style={{color: '#000000'}}>
                                Get the kids buzzing in a fun and safe way!
                            </p>
                            <ul>
                                <li className='row-ac feature-text mb-8'>
                                    <SvgStarFilled color={'#3078F6'} width={24} height={24}/>
                                    <span className='black ml-12'><strong>15</strong> unique and adorable faces</span>
                                </li>
                                <li className='row-ac feature-text mb-8'>
                                    <SvgStarFilled color={'#3078F6'} width={24} height={24}/>
                                    <span className='black ml-12'>Easy access refill canister for the long sesh</span>
                                </li>
                                <li className='row-ac feature-text mb-8'>
                                    <SvgStarFilled color={'#3078F6'} width={24} height={24}/>
                                    <span className='black ml-12'>Soft silicon tube for enhanced grip</span>
                                </li>
                                <li className='row-ac feature-text mb-40'>
                                    <SvgStarFilled color={'#3078F6'} width={24} height={24}/>
                                    <span className='black ml-12'><span className='red'
                                                                        style={{fontWeight: 600}}>NEW&nbsp;</span> <strong>50</strong> disposable COVID safe tube tips included</span>
                                </li>
                            </ul>
                            <button className='mr-16 red-button' onClick={() => this.props.history.push("/buy")}>
                                Buy Now
                            </button>
                        </div>
                        <img src={gif} style={{width: '40%'}}/>
                    </div>
                </section>
                <section ref={this.reviewsRef} style={{backgroundColor: 'white'}}>
                    <div className={"col-ac-jc black-text p-100 pt-0 headline"}>
                        <img src={fda} style={{width: 100}}/>
                        <div className='body1' style={{width: 460, textAlign: "center"}}>
                            My First Hookah is recommended by the Family Drugs Association as a fun and addicting way to
                            get the kids started early. Just don't think too much about it.
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ProductSection
