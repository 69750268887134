import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";
import SvgXCircle from "../icons/XCircle";

class ImageModal extends Component {
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={this.props.toggle}
                // style={customStyles}
                contentLabel="Example Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-100%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            className='modal-body relative'>
                    <div className='pointer' onClick={this.props.toggle}>
                        <SvgXCircle className={'ml8 mt-8'}/>
                    </div>
                    <img src={this.props.selectedImage} height={this.props.mobile ? 400 : 800}/>
                </motion.div>
            </Modal>
        )
    }
}

export default ImageModal
