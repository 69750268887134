import {Component} from "react";
import SvgTwitter from "../icons/Twitter";
import SvgFacebook from "../icons/Facebook";
import logo from '../images/logo.png'


class Footer extends Component {
    render() {
        return (
            <section className='' style={{padding: '5% 15%', backgroundColor: '#00000030'}}>
                <div className='row-jb mt-20'>
                    <div style={{maxWidth: 300}}>
                        <img src={logo} style={{height: 45}}/>
                        <div className='row-ac mt-16'>
                            <a target={"_blank"}
                               href={"https://twitter.com/AdamPadilla"}>
                                <SvgTwitter className='mr-12'/>
                            </a>
                            <a target={"_blank"}
                               href={"https://www.instagram.com/adam.the.creator"}>
                                <SvgFacebook style={{color:'white'}}/>
                            </a>
                        </div>
                        <p style={{fontSize: 12}} className='mt-32'>© My First Hookah 2022</p>
                    </div>
                    <div className=''>
                        <p className='uppercase body2-medium mt-0 o5'>Links</p>
                        <ul>
                            <li className='body1-emphasized mb-4'>
                                Terms of Service
                            </li>
                            <li className='body1-emphasized mb-4'>
                                Privacy Policy
                            </li>
                        </ul>
                    </div>
                </div>
                <p className='body3-medium mt-32' style={{color: '#FFFFFF50'}}>
                    My First Hookah is not affiliated with any brand in anyway. This is a satirical creation by Adam the
                    Creator and should not be taken seriously. Please do not let your children hit hookahs.
                </p>
            </section>
        )
    }
}

export default Footer
