import React, {Component} from "react";
import logo from "../../images/logo.png";
import hookahs from "../../images/hookahs1.png";
import SvgStarFilled from "../../icons/StarFilled";
import {REVIEWS, REVIEWS_MOBILE} from "../../consts/consts";

class SplashHeader extends Component {
    render() {
        if (this.props.mobile) {
            return (
                <div className='section-padding-mobile'>
                    <div style={{marginTop:'40%'}} className='col-ac-jc'>
                        <img style={{width: '80%'}} src={hookahs}/>
                        <h1 className='splash-head mb-16 mt-12' style={{fontSize:32}}>
                            Fun, family-friendly toy to get the kids hooked for hours
                        </h1>
                        <div className='row-ac mt-32'>
                            <button className='mr-16 red-button' onClick={() => this.props.history.push("/buy")}>
                                SHOP NOW
                            </button>
                        </div>
                    </div>
                    <div className='row-ac-jc pv-20 mt-52' style={{color: 'black', flexWrap:'wrap'}}>
                    {REVIEWS_MOBILE.map(review =>
                        <div key={review.desc} className={'text-center mh-12'} style={{maxWidth:'40%', minHeight:100}}>
                            <div className='row-ac-jc'>
                                {([1, 2, 3, 4, 5]).map(i =>
                                    <SvgStarFilled color={'#FFEA04'} key={i}/>
                                )}
                            </div>
                            <p className='feature-label-mobile uppercase m-0' style={{color: '#000000A0'}}>
                                {review.title}
                            </p>
                            <p className='feature-mobile uppercase mt-4' style={{color: '#FFFFFF'}}>
                                {review.desc}
                            </p>
                        </div>
                    )}
                </div>
                </div>
            )
        }
        else return (
            <section className=' col-ac-jc section-padding pt-80'>
                <div className='row-ac-jc'>
                    <div>
                        <img src={logo} width={this.props.mobile ? 50 : 400}/>
                        <h1 className='splash-head mb-16 mt-12 ml-12'>
                            Fun, family-friendly toy to get the kids hooked for hours
                        </h1>
                        <div className='row-ac mt-32 ml-12'>
                            <button className='mr-16 red-button' onClick={() => this.props.history.push("/buy")}>
                                SHOP NOW
                            </button>
                        </div>
                    </div>
                    <img style={{width: '50%'}} src={hookahs}/>
                </div>
                <div className='row-ac-jc mt-32'>
                    <div className='breakline'/>
                    <p className='m-0 mh-32 feature-text'>Featured Reviews</p>
                    <div className='breakline'/>
                </div>
                <div className='row-ac-jb w-90p pv-20' style={{color: 'black'}}>
                    {REVIEWS.map(review =>
                        <div key={review.desc} className={'text-center mh-12'} style={{maxWidth: 225}}>
                            <div className='row-ac-jc'>
                                {([1, 2, 3, 4, 5]).map(i =>
                                    <SvgStarFilled color={'#FFEA04'} key={i}/>
                                )}
                            </div>
                            <p className='feature-label uppercase m-0' style={{color: '#000000A0'}}>
                                {review.title}
                            </p>
                            <p className='feature uppercase mt-8' style={{minHeight: 100, color: '#FFFFFF'}}>
                                {review.desc}
                            </p>
                        </div>
                    )}
                </div>
            </section>
        )
    }
}

export default SplashHeader
