export const CONTRACT_ADDRESS = '0x2a07964d3b3e6a39a242e02092500a593fb7550f'
export const MINT_PRICE_WEI = 1 * 1e18
export const MINT_FUNCTION_SELECTOR = '0x1249c58b'
export const MAINNET_CHAIN_ID = '0x1'

export const REVIEWS = [
    {
        title: 'Teen Moms (\'05 cast)',
        desc: 'Healthily Addicting',

    },
    {
        title: 'Smokers Lounge Mag',
        desc: 'Top 10 baby shower gift of the year'
    },
    {
        // title: '* * * * *',
        desc: 'Smooth toke. Suitable for all ages',
        subdesc: ''
    },
    {
        title: 'Nicholas Cage',
        desc: 'A national treasure'
    },
    {
        title: 'Pipe Principals',
        desc: 'Resale value reason alone'
    },
]

export const REVIEWS_MOBILE = [
    {
        title: 'Teen Moms (\'05 cast)',
        desc: 'Healthily Addicting',

    },
    {
        title: 'Smokers Lounge Mag',
        desc: 'Top 10 baby shower gift of the year'
    },
    {
        title: 'Nicholas Cage',
        desc: 'A national treasure'
    },
    {
        title: 'Pipe Principals',
        desc: 'Resale value reason alone'
    },
    {
        // title: '* * * * *',
        desc: 'Smooth toke. Suitable for all ages',
        subdesc: ''
    },
]
