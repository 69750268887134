import React, {Component} from "react";
import Navbar from "../components/Navbar";
import gif from '../images/hookah.gif'
import hookah from '../images/hookah1.png'
import hookahs from '../images/hookahs1.png'
import fda from '../images/fdastamp.png'
import SvgStarFilled from "../icons/StarFilled";
import logo from '../images/logo.png'
import Reviews from "../components/Reviews";
import Footer from "../components/Footer";
import Marquee from "react-fast-marquee";
import exclamation from '../images/exclamations.png'
import ImageModal from "../components/ImageModal";
import SplashHeader from "./Splash/SplashHeader";
import SaleSection from "./Splash/SaleSection";
import ProductSection from "./Splash/ProductSection";

const NAV_TABS = [
    {
        tab: 'Home',
        route: 'home'
    },
    // {
    //     tab: 'Accodlades',
    //     route: 'features'
    // },
    {
        tab: 'Product',
        route: 'product'
    },
    {
        tab: 'Reviews',
        route: 'reviews'
    },
]

const MARQUEE = ['Minting Now!', 'Minting Now!', 'Minting Now!', 'Minting Now!', 'Minting Now!', 'Minting Now!', 'Minting Now!', 'Minting Now!', 'Minting Now!', 'Minting Now!', 'Minting Now!', 'Minting Now!']

class Splash extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 'home',
            scrollPosition: 0,
            modalOpen: false,
            salesRefHeight: 0,
            mobile:false
        }
        this.topRef = React.createRef();
        this.featuresRef = React.createRef();
        this.productRef = React.createRef();
        this.reviewsRef = React.createRef();
        this.salesRef = React.createRef();
    }

    scrollTo = (route) => {
        if (route === 'home') {
            this.topRef.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'features') {
            this.featuresRef.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'product') {
            this.productRef.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'reviews') {
            this.reviewsRef.current.scrollIntoView({behavior: 'smooth'})
        }
    }


    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

        resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200) && (window.innerHeight > window.innerWidth)
        });
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop
        const featuresRef = this.featuresRef.current.offsetTop + window.innerHeight
        const salesRef = this.productRef.current.offsetTop
        const productRef = this.productRef.current.offsetTop + window.innerHeight
        const reviewsRef = this.reviewsRef.current.offsetTop + window.innerHeight
        this.setState({
            salesRefHeight: salesRef,
            scrollPosition: winScroll,
            activeTab: winScroll < 100 ? 'home' : winScroll < featuresRef ? 'features' : winScroll < productRef ? 'product' : 'reviews'
        })
    }

    render() {
        return (
            <div>
                <Navbar
                    mobile={this.state.mobile}
                    salesRefHeight={this.state.salesRefHeight} scrollTo={this.scrollTo}
                        scrollPosition={this.state.scrollPosition} history={this.props.history}
                        activeTab={this.state.activeTab} tabs={NAV_TABS}/>
                <div ref={this.topRef}>
                    <SplashHeader mobile={this.state.mobile} history={this.props.history}/>
                </div>
                <Marquee gradient={false} className='marquee'>
                    {MARQUEE.map(text =>
                        <div className='headline mh-60 pv-12' style={{color: 'white', textTransform: 'uppercase'}}>
                            {text}
                        </div>
                    )}
                </Marquee>
                <div ref={this.salesRef}>
                    <SaleSection mobile={this.state.mobile} history={this.props.history}/>
                </div>
                   <div ref={this.productRef}>
                    <ProductSection mobile={this.state.mobile} history={this.props.history}/>
                </div>
                <div ref={this.reviewsRef}>
                    <Reviews mobile={this.state.mobile} history={this.props.history}/>
                </div>
                <Footer mobile={this.state.mobile}/>
            </div>
        )
    }
}

export default Splash
