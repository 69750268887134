import * as React from "react";

function SvgXCircle(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 20.571A8.571 8.571 0 1012 3.43a8.571 8.571 0 000 17.142zM14.571 9.429L9.43 14.57M9.429 9.429l5.142 5.142"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgXCircle;
