import React, {Component} from "react";
import hookah from "../images/hookah1.png";
import Navbar from "../components/Navbar";

import muffin1 from '../images/muffinz/muffin5.GIF'
import muffin2 from '../images/muffinz/muffin8.gif'
import muffin3 from '../images/muffinz/muffin19.png'
import muffinsheader from '../images/muffinz/muffinzheader2.png'
import Footer from "../components/Footer";
import thumb1 from '../images/thumb1.png'
import thumb2 from '../images/thumb2.png'
import thumb3 from '../images/hookahs/9.png'
import thumb4 from '../images/thumb4.png'
import SvgHome from "../icons/Home";
import SvgArrowBack from "../icons/ArrowBack";
import ImageModal from "../components/ImageModal";
import sticker from '../images/sticker4.png'
import logo from "../images/logo.png";
import {loadEthereum} from "../utils/mint";
import {abi} from "../consts/abi"
import {ethers} from 'ethers'
import SuccessModal from '../components/SuccessModal'

import {CONTRACT_ADDRESS} from "../consts/consts";

class Buy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imageModal: false,
            selectedImage: null,
            mobile: false,
            currentSupply: 0,
            oldCurrentSupply: 0,
            totalSupply: 15,
            nextImage: null,
            successModal: false,
            success: true

        }
        this.provider = ethers.getDefaultProvider();
        this.contract = new ethers.Contract(CONTRACT_ADDRESS, abi);
    }

    getCurrentSupply = async () => {
        const readContract = this.contract.connect(this.provider)
        let currentSupply = await readContract.currentSupply()
        return parseInt(currentSupply)
    }

    getImage = async (imageNumber) => {
        const readContract = this.contract.connect(this.provider)
        let tokenURI = await readContract.tokenURI(imageNumber)
        console.log("URI", tokenURI)
        console.log("IMAGE READ", tokenURI)
        return tokenURI
    }

    openImageModal = (selectedImage) => {
        this.setState({selectedImage, imageModal: true})
    }

    closeImageModal = () => {
        this.setState({imageModal: false})
    }

    callback = (txhash) => {
        console.log("IN CALLBACK", txhash)
        console.log("HASH FIRST", txhash)
        this.provider.waitForTransaction(txhash).then(receipt => {
            console.log("HASH", txhash)
            console.log("SUCCESS!", receipt)
            this.setState({successModal: true});
            this.getCurrentSupply().then(currentSupply => {
                this.setState({currentSupply})
            })
        })
    }

    mintNow = async () => {
        const txhash = await loadEthereum(this.callback);
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.getCurrentSupply().then(currentSupply => {
            this.setState({currentSupply, oldCurrentSupply: currentSupply});
        })
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200) && (window.innerHeight > window.innerWidth)
        });
    }


    render = () => {
        console.log("STATE", this.state)
        const disabled = this.state.currentSupply === 15;
        if (this.state.mobile) {
            return (
                <div>
                    <Navbar page={2} mobile={true}/>
                    <div className={"col-plain section-padding-mobile relative"} style={{backgroundColor: 'white'}}>
                        <div className='row-ac pointer-hvr' style={{marginTop: '20%'}}
                             onClick={() => this.props.history.push("/")}>
                            <SvgArrowBack color='black' width={25} height={25}/>
                            <p className='mb-4 mt-0 feature-text-mobile black'
                               style={{color: 'black', opacity: 1}}>Back</p>
                        </div>
                        <div style={{flex: 1}} className={"black-text"}>
                            <p className='m-0 mt-40 headline2-arvo-mobile red'>SALE LIVE!</p>
                            <p className={"splash-head-lg-mobile mb-0 mt-0"}>My First Hookah NFT</p>
                            <p className={"headline-mobile mt-4"}>from Adam the Creator</p>
                            <div className='w-100p relative'>
                                <img src={sticker} width={100} className={'absolute'} style={{top: 0, right: 0}}/>
                                <img src={hookah} style={{width: '100%'}}/>
                            </div>
                            <div className='row-ac-jb mb-20' style={{flexWrap: 'wrap'}}>
                                <div className='thumbnail-mobile' onClick={() => this.openImageModal(thumb1)}>
                                    <img src={thumb3} width={50}/>
                                </div>
                                <div className='thumbnail-mobile' onClick={() => this.openImageModal(thumb2)}>
                                    <img src={thumb2} width={50}/>
                                </div>
                                <div className='thumbnail-mobile' onClick={() => this.openImageModal(thumb3)}>
                                    <img src={thumb1} width={50}/>
                                </div>
                                <div className='thumbnail-mobile' onClick={() => this.openImageModal(thumb4)}>
                                    <img src={thumb4} width={50}/>
                                </div>
                            </div>
                            <strong>About</strong>
                            <p className={"body1-mobile mb-0"}>
                                My First Hookah is the genesis NFT drop of Adam the Creator, legendary viral digital
                                content creator and visual artist. Holders will be able to collaborate 1v1 with Adam the
                                Creator to create their very own meme to be turned into a 1/1 NFT and featured on his
                                account. Holders will be able to go under the hood with Adam to see the creative process
                                of bringing their idea to life. Half of all proceeds of My First Hookah will be donated
                                to the American Cancer Society.
                            </p>
                            <p>
                                <strong>Benefits</strong>
                                <ul className='body1-mobile mt-12 ml-8'>
                                    <li className='mt-4'>
                                        • Collaborate directly with Adam to create your own meme to be turned into a 1/1
                                        exclusive NFT and featured on
                                        Adam's account
                                    </li>
                                    <li className='mt-4'>
                                        • 2 free NFT's from Adam's upcoming generative drop the <a
                                        href={"https://www.sparklemuffinz.com/"} target={"_blank"}>SparkleMuffinz</a>
                                    </li>
                                    <li className='mt-4'>
                                        • 50% of all proceeds donated to lung cancer research
                                    </li>
                                </ul>
                            </p>
                            <div className='row-ac mt-32'>
                                <div className='mr-48'>
                                    <p className={"small-headline m-0"}>1 ETH</p>
                                    <p className='feature-text-mobile mt-0'>Price</p>
                                </div>
                                <div>
                                    <p className={"small-headline m-0"}>{this.state.currentSupply}/15</p>
                                    <p className='feature-text-mobile mt-0'>Minted</p>
                                </div>
                            </div>
                            <button className='mt-20 blue-button' onClick={this.mintNow} disabled={disabled}>
                                {disabled ? "Sold Out" : "Mint Now"}
                            </button>
                        </div>
                        <div className={"section-padding-mobile text-center"} style={{backgroundColor: 'white'}}>
                            <div className='row-ac-jc mb-24 mt-52'>
                                <div className='breakline-mobile' style={{marginRight: '5%'}}/>
                                <p className={"headline-mobile black m-0"}>Looking for something else?</p>
                                <div className='breakline-mobile' style={{marginLeft: '5%'}}/>
                            </div>
                            <p className={"black mb-24 body1-mobile"}>Check out Adam the Creator's first generative NFT
                                drop: the
                                SparkleMuffinz, a collection of 8,888 upgradable NFTs on the ethereum blockchain. 250 of
                                the
                                SparkleMuffinz will grant the holders access to Adam the Creator's personal mentorship
                                program.
                            </p>
                        </div>
                        <div className='mb-12'>
                            <a
                                href={"https://www.sparklemuffinz.com/"} target={"_blank"}>
                                <img src={muffinsheader} width={'100%'} className='m-0 p-0'/>
                            </a>
                        </div>
                    </div>
                    <Footer/>
                    <ImageModal mobile isOpen={this.state.imageModal} selectedImage={this.state.selectedImage}
                                toggle={this.closeImageModal}/>
                    <SuccessModal
                        mobile={true}
                        isOpen={this.state.successModal}
                        currentSupply={this.state.oldCurrentSupply}
                        toggle={() => this.setState({successModal: false})}/>
                </div>
            )
        }
        return (
            <>
                <div className={"col-ac"} style={{backgroundColor: 'white'}}>
                    <Navbar page={2}/>
                    <div className={"row-ac-ja checkout-padding relative"}>
                        <div style={{flex: 1}}>
                            <div className='row-ac pointer-hvr' onClick={() => this.props.history.push("/")}>
                                <SvgArrowBack color='black' width={25} height={25}/>
                                <p className='mb-4 mt-0 feature-text black'
                                   style={{color: 'black', opacity: 1}}>Back</p>
                            </div>
                            <div className='w-100p relative'>
                                <img src={sticker} width={200} className={'absolute'} style={{top: 40, right: 40}}/>
                                <img src={hookah} style={{width: '100%'}}/>
                            </div>
                            <div className='row-ac-jb' style={{flexWrap: 'wrap'}}>
                                <div className='thumbnail' onClick={() => this.openImageModal(thumb1)}>
                                    <img src={thumb3} width={100}/>
                                </div>
                                <div className='thumbnail' onClick={() => this.openImageModal(thumb2)}>
                                    <img src={thumb2} width={100}/>
                                </div>
                                <div className='thumbnail' onClick={() => this.openImageModal(thumb3)}>
                                    <img src={thumb1} width={100}/>
                                </div>
                                <div className='thumbnail' onClick={() => this.openImageModal(thumb4)}>
                                    <img src={thumb4} width={100}/>
                                </div>
                            </div>
                        </div>
                        <div style={{flex: 1}} className={"black-text ml-52"}>
                            <img src={logo} width={100}/>
                            <p className='m-0 mt-40 headline2-arvo red'>SALE LIVE!</p>
                            <p className={"splash-head-lg mb-0 mt-0"}>My First Hookah NFT</p>
                            <p className={"headline mt-4"}>from Adam the Creator</p>
                            <p className={"mb-0"}>
                                My First Hookah is the genesis NFT drop of Adam the Creator, legendary viral digital
                                content creator and visual artist. Holders will be able to collaborate 1v1 with Adam the
                                Creator to create their very own meme to be turned into a 1/1 NFT and featured on his
                                account. Holders will be able to go under the hood with Adam to see the creative process
                                of bringing their idea to life. Half of all proceeds of My First Hookah will be donated
                                to the American Cancer Society.
                            </p>
                            <p>
                                <strong>Benefits</strong>
                                <ul className='mt-4 ml-8'>
                                    <li className='mt-4'>
                                        • Collaborate directly with Adam to create your own meme to be turned into a 1/1
                                        exclusive NFT and featured on
                                        Adam's account
                                    </li>
                                    <li className='mt-4'>
                                        • 2 free NFTs from Adam's upcoming generative drop the <a
                                        href={"https://www.sparklemuffinz.com/"} target={"_blank"}>SparkleMuffinz</a>
                                    </li>
                                    <li className='mt-4'>
                                        • 50% of all proceeds donated to lung cancer research
                                    </li>
                                </ul>
                            </p>
                            <div className='row-ac mt-32'>
                                <div className='mr-48'>
                                    <p className={"small-headline m-0"}>1 ETH</p>
                                    <p className='feature-text mt-0'>Price</p>
                                </div>
                                <div>
                                    <p className={"small-headline m-0"}>{this.state.currentSupply}/15</p>
                                    <p className='feature-text mt-0'>Minted</p>
                                </div>
                            </div>
                            <button className='mt-20 blue-button' onClick={this.mintNow} disabled={disabled}>
                                {disabled ? "Sold Out" : "Mint Now"}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={"section-padding text-center"} style={{backgroundColor: 'white'}}>
                    <div className='row-ac-jc mb-24'>
                        <div className='breakline' style={{marginRight: '5%'}}/>
                        <p className={"headline black m-0"}>Looking for something else?</p>
                        <div className='breakline' style={{marginLeft: '5%'}}/>
                    </div>
                    <p className={"black mb-24"}>Check out Adam the Creator's first generative NFT drop: the
                        SparkleMuffinz, a collection of 8,888 upgradable NFTs on the ethereum blockchain. 250 of the
                        SparkleMuffinz will grant the holders access to Adam the Creator's personal mentorship
                        program.</p>
                </div>
                <div style={{cursor: "pointer"}}>
                    <a
                        href={"https://www.sparklemuffinz.com/"} target={"_blank"}>
                        <img src={muffinsheader} width={'100%'} className='m-0 p-0'/>
                    </a>
                </div>
                <Footer/>
                <ImageModal isOpen={this.state.imageModal} selectedImage={this.state.selectedImage}
                            toggle={this.closeImageModal}/>
                <SuccessModal
                    mobile={false}
                    isOpen={this.state.successModal}
                    toggle={() => this.setState({successModal: false})}
                    currentSupply={this.state.oldCurrentSupply}/>

            </>
        )
    }
}

export default Buy
