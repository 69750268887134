import React, {Component} from "react";
import SvgTwitter from "../icons/Twitter";
import SvgFacebook from "../icons/Facebook";
import SvgLink from '../icons/Link'

import logo from '../images/logo.png'
import logosm from '../images/logosm.png'
import opensea from '../images/opensea.png'

const wait = (timeout) => {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}


class Navbar extends Component {
    state = {
        hoveringNav: false,
        mobileNavOpen: false
    }

    hoverNav = () => {
        if (this.props.scrollPosition >= 100) {
            wait(350).then(() => this.setState({hoveringNav: true}))
        }
    }

    render() {
        return (
            <nav className='nav'
                 style={{
                     height: ((this.props.scrollPosition <= 100) || this.state.hoveringNav) ? 80 : 60,
                     borderBottom: this.props.scrollPosition <= 100 ? '3px solid #3078F6' : this.props.scrollPosition <= this.props.salesRefHeight ? '3px solid red' : '3px solid #003459',
                     // backgroundColor: this.props.scrollPosition >= 100 && '#00000060'
                 }}
                 onMouseEnter={this.hoverNav}
                 onMouseLeave={() => this.setState({hoveringNav: false})}>
                <div style={{flex: 1}} className='pointer' onClick={() => this.props.history.push("/")}>
                    {((this.props.scrollPosition <= 100) || this.state.hoveringNav) ?
                        <img src={logo} style={{height: 60}}/>
                        :
                        <img src={logosm} className='mt-4' style={{height: 20}}/>
                    }
                </div>
                {(this.props.mobile && this.props.page !== 2) &&
                <div className='row-ac'>
                    {/*Todo: comment afer mint*/}
                    <button className='red-button-mobile' onClick={() => this.props.history.push("/buy")}
                            style={{fontSize: 14}}>
                        MINT HERE
                    </button>
                    {/*Todo: uncomment afer mint*/}
                    {/*<a href={'https://opensea.io/collection/my-first-hookah'} target={'_blank'}>*/}
                    {/*    <img src={opensea} height={26} width={26}/>*/}
                    {/*</a>*/}
                </div>
                }
                {!this.props.mobile &&
                <ul style={{flex: 2}}>
                    {this.props.page !== 2 &&
                    this.props.tabs.map(tab =>
                        <li className=' tab pointer' key={tab.tab} style={{
                            height: this.props.scrollPosition < 100 ? 80 : ((this.props.scrollPosition <= 100) || !this.state.hoveringNav) && 43
                        }}
                            onClick={() => this.props.scrollTo(tab.route)}>
                            <div className='title-emphasized'
                                 style={{color: this.props.activeTab === tab.route && 'white'}}>
                                {tab.tab}
                            </div>
                            <div style={{height: this.props.activeTab === tab && 4}} className='nav-border'/>
                        </li>
                    )
                    }
                </ul>
                }
                {
                    <div style={{flex: 1,}} className='row-ac-je '>
                        {/*Todo: uncomment afer mint*/}
                        {/*{!this.props.mobile &&*/}
                        {/*<a href={'https://opensea.io/collection/my-first-hookah'} target={'_blank'}>*/}
                        {/*    <img src={opensea} height={26} width={26}/>*/}
                        {/*</a>*/}
                        {/*}*/}
                        <a className='contract-container' style={{color: "white", marginLeft: 20}} target={"_blank"}
                           href={"https://etherscan.io/address/0x2a07964d3b3e6a39a242e02092500a593fb7550f"}>
                            <SvgLink className='mr-12'/>
                            <div>
                                <p className='body3-emphasized mb-4 mt-0' style={{fontSize: this.props.mobile && 9}}>
                                    Contract Address:
                                </p>
                                <p className={this.props.mobile ? 'm-0 body2-emphasized' : 'm-0 body1-emphasized'}>0x2a0...7550f</p>
                            </div>
                        </a>
                    </div>
                }
            </nav>
        )
    }
}

export default Navbar
