import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";
import SvgXCircle from "../icons/XCircle";

class SuccessModal extends Component {
    render() {
        console.log(this.props)
        console.log("IMAGE", "../images/hookahs/" + this.props.currentSupply + ".png")
        return (
            <Modal
                isOpen={this.props.isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={this.props.toggle}
                // style={customStyles}
                contentLabel="Example Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-100%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            className='modal-body relative'>
                    <div className={this.props.mobile ? 'modal-body-mobile' : 'modal-body'}>
                        <div className='pointer' onClick={this.props.toggle}>
                            <SvgXCircle className={'ml8 mt-8'}/>
                        </div>
                        <div className='col-ac-jc text-center'>
                            <p className={this.props.mobile ? 'splash-head-mobile mb-0 mt-0' : 'splash-head mb-0 mt-0'}>
                                Congratulations!
                            </p>
                            <p className={this.props.mobile ? 'body1-mobile' : 'body1'}>
                                You successfully minted a hookah, variant <strong>{this.props.currentSupply+1}</strong> to be specific. Thanks so
                                much for
                                supporting the project.
                            </p>
                            {this.props.currentSupply !== 15 && <img src={require("../images/hookahs/" + this.props.currentSupply + ".png")} height={this.props.mobile ? 250 : 500}/>}`
                        </div>
                    </div>
                </motion.div>
            </Modal>
        )
    }
}

export default SuccessModal
