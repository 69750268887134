import React, {Component} from "react";
import exclamation from "../../images/exclamations.png";
import hookahs from "../../images/hookahs1.png";

class SaleSection extends Component {
    render() {
        if (this.props.mobile) {
            return (
                <section className='sale-section black-text'>
                    <div className={"sale-section-header"}>
                        <p className={"mr-8 headline2-arvo-mobile"}>2 Free Hoses!</p>
                        <p className=''>|</p>
                        <p className={"ml-8 headline2-arvo-mobile"}>2 Free Bubble Flavors!</p>
                    </div>
                    <div className='sale-banner mv-20 mr-32' style={{padding:'24px 0px', justifyContent:'center'}}>
                        <img src={exclamation} width={60} height={75}/>
                        <div className='ml-24'>
                            <p className={"splash-head-lg-mobile mt-0 mb-0"}>Blow Out<br/>Sale!</p>
                            <p className='feature-text-mobile mt-4 mb-0'>Until supplies last.</p>
                        </div>
                    </div>
                    <div className={"col-ac-jc pv-20 pb-40"} style={{flex: 1}}>
                        <div className='w-80p col-ac-jc text-center'>
                            <p className={"large-headline-mobile mt-0 mb-20"}>Holiday bundle savings are here!</p>
                            <div className='mb-12'>
                                <img src={hookahs} width={100}/>
                            </div>
                            <button className='mr-16 red-button' onClick={() => this.props.history.push("/buy")}>
                                CLAIM NOW
                            </button>
                        </div>
                    </div>
                </section>
            )
        }
        return (
            <section className='sale-section'>
                <div className={"col-plain black-text"}>
                    <div className={"sale-section-header"}>
                        <p className={"mr-36 headline2-arvo"}>2 Free Hoses!</p>
                        <p className=''>|</p>
                        <p className={"ml-36 headline2-arvo"}>2 Free Bubble Flavors!</p>
                    </div>
                    <div className="row-ac pv-16">
                        <div className='sale-banner'>
                            <img src={exclamation} width={120} height={150}/>
                            <div className='ml-24 mr-64'>
                                <p className={"splash-head-lg mt-0 mb-0"}>Blow Out<br/>Sale!</p>
                                <p className='feature-text mt-4 mb-0'>Until supplies last.</p>
                            </div>
                        </div>
                        <div className={"col-ac-jc"} style={{flex: 1}}>
                            <p className={"large-headline mt-0 mb-20"}>Holiday bundle savings are here!</p>
                            <div className='mb-12'>
                                <img src={hookahs} width={100}/>
                            </div>
                            <button className='mr-16 red-button' onClick={() => this.props.history.push("/buy")}>
                                CLAIM NOW
                            </button>
                        </div>
                    </div>
                    <div className='sale-section-footer'>
                        <p className={"mr-36"}>Limited Time Only</p>
                        <p className={""}>|</p>
                        <p className={"ml-36"}>Discount Applied Directly at Checkout</p>
                    </div>
                </div>
            </section>
        )
    }
}

export default SaleSection
